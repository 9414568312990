import { SessionStatus } from "./session";

export interface Survey {
    id: string;
    name: string;
    description?: string;
    randomizedQuestions: boolean;
    pages: Page[];
    rules?: Rule[];
}


export interface Page {
    id: string;
    order: number;
    title: string;
    description?: string;
    questions: Question[];
}

export interface Question {
    id: string;
    title: string;
    required: boolean;
    preview?: boolean;
    order: number; // order of the question in the survey
    questionType: string;
    options?: QuestionOption[];
    subQuestions?: SubQuestion[];
    answer: QuestionAnswer;
}

export interface QuestionAnswer {
    text: string | null;
    date: Date | null;
}

export interface SubQuestion {
    id: string;
    title: string;
    required: boolean;
    order: number;
    questionType: string;
    options?: QuestionOption[];
    answer: QuestionAnswer;
}

export interface QuestionOption {
    id: string;
    title: string;
    order: number;
    conditionalText?: string;
    isSelected: boolean;
}

export interface Rule {
    questionId: string;
    optionId: string;
    operator: string;
    action: string;
    targetId: string;
    targetType: string;
}

export interface OptionConditional {
    title?: string;
    placeholder?: string;
}

export enum QuestionType {
    TEXT = "TEXT",
    RADIO = "RADIO",
    DATE = "DATE",
    READONLY = "READONLY",
    RADIOWITHTEXT = "RADIOWITHTEXT",
    PARAGRAPH = "PARAGRAPH",
    MATRIX = "MATRIX",
    LINEARSCALE = "LINEARSCALE",
    CONTROL = "CONTROL",
    DROPDOWN = "DROPDOWN"
}

export interface SurveyTrigger {
    questionId: string | number;
    type: string;
    expression: (value: any) => boolean;
}

export enum RuleType {
    SHOW = "SHOW",
    HIDE = "HIDE",
    DISABLE = "DISABLE",
    SKIP = "SKIP",
    SUBMITSURVEY = "SUBMIT",
    CONTROL = "CONTROL",
}

export interface SurveyView {
    id: string;
    name: string;
    description: string;
}

export interface SurveyResponse {
    respondent: Respondent;
    surveyId: string;
    languageId: string;
    timelineId: string;
    sessionId: string;
    answers: Answer[];
}

export interface Respondent {
    userId: string;
}

export interface Answer {
    questionId: string;
    optionId: string;
    textAnswer?: string;
}

export interface SessionStartDto {
    assessorId: string;
    clientId: string;
    processId: string;
    sessionId: string;
    surveyId: string;
    surveyName: string;
    timelineId: string;
    currentStep: string;
}

// TODO: Remove this model (only temporary)
export interface TempSession {
    id: string;
    status: SessionStatus;
    process: string;
    startTime: Date;
    clientFullName: string;
    assessorFullName: string;
    timeline: string;
}

export interface AnswerSubmit {
    questionId: string;
    optionId?: string | null;
    surveyId: string;
    timelineId: string;
    sessionId: string;
    text?: string | null;
    date?: Date | null;
    selected?: boolean;
}

type LanguageDirection = "rtl" | "ltr";

export interface SurveyLanguage {
    name: string;
    code: string;
    direction: LanguageDirection;
}

export interface SurveyTranslateField {
    id: string;
    entity: string;
    property: string;
    value: string;
    languageId: string;
}

export enum Group {
    A = "Group A",
    B = "Group B",
    C = "Group C",
}