import { makeAutoObservable, runInAction } from "mobx";

import agent from "../utils/agent";
import { Institution, InstitutionFormValues, InstitutionUserDto } from "../data/models/institution";
import { PermissionDto } from "../data/models/role";
import { UserTenant } from "../data/models/tenant";
import { store } from "./store";
import { ResponsiblePersonResponseDto, TenantUserDto } from "../data/models/user";

export default class InstitutionStore {
    institutions: Map<string, Institution> = new Map<string, Institution>();
    institutionUsers: Map<string, TenantUserDto> = new Map<string, TenantUserDto>();
    usersByInstitution: Map<string, InstitutionUserDto[]> = new Map<string, InstitutionUserDto[]>();
    responsiblePeople: Map<string, ResponsiblePersonResponseDto[]> = new Map<string, ResponsiblePersonResponseDto[]>();
    selectedUserInstitution?: PermissionDto;
    loading = false;
    currentInstitutionStudyType: string = '';

    constructor() {
        makeAutoObservable(this)
    }

    get institutionUserList() {
        return Array.from(this.institutionUsers.values());
    }

    get institutionList() {
        return Array.from(this.institutions!.values());
    }

    get usersByInstitutionList() {
        const users = Array.from(this.usersByInstitution!.values());
        return users.flat();
    }

    get responsibleUserList() {
        const people = Array.from(this.responsiblePeople!.values());
        return people.flat();
    }

    removePersonByInstitutionId = (institutionId: string) => {
        this.responsiblePeople.delete(institutionId);
    }

    clearResponsiblePeople = () => {
        this.responsiblePeople.clear();
    }

    setSelectedUserInstitution = (institutionId: string | undefined) => {
        if (institutionId) {
            this.getPermissions(institutionId)
                .then(() => store.commonStore.setSelectedInstitution(institutionId));
        } else {
            this.selectedUserInstitution = undefined;
        }
    }

    updateUserSelectedInstitution = (selectedTenant: UserTenant) => {
        if (selectedTenant) {
            if (selectedTenant && selectedTenant.institutions.length > 0) {
                const defaultInstitution = selectedTenant.institutions.find(i => i.isDefault);
                if (defaultInstitution) {
                    this.setSelectedUserInstitution(defaultInstitution.id);
                } else {
                    const firstInstitution = selectedTenant.institutions[0];
                    this.setSelectedUserInstitution(firstInstitution.id);
                }
            }
        }
    }

    setDefaultUserInstitution = (tenants: UserTenant[]) => {
        tenants.forEach((tenant) => {
            if (tenant.isDefault) {
                tenant.institutions.forEach((institution) => {
                    if (institution.isDefault) {
                        this.setSelectedUserInstitution(institution.id);
                    }
                });
            }
        });
    }

    getInstitutions = async (tenantId: string) => {
        store.loadingStore.startLoading(this.getInstitutions);
        this.loading = true;
        try {
            const institutions: Institution[] = await agent.Institutions.getByTenant(tenantId);
            runInAction(() => {
                this.institutions.clear();
                institutions.forEach((institution) => this.institutions!.set(institution.id, institution));
            });
        } catch (error) {
            throw error;
        } finally {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.getInstitutions);
        }
    }

    /**
    * @note This method fetches both institution data and assigned users.
    * Use `getInstitutionById` if only institution data is required for improved performance.
    * Refactor, or rename the function in the future to reflect the api calls, but be cautios of breaking changes.
    */
    getInstitution = async (institutionId: string) => {
        store.loadingStore.startLoading(this.getInstitution);
        this.loading = true;
        try {
            const institution = await agent.Institutions.getById(institutionId);
            const users = await agent.Institutions.getAssignedUsers(institutionId);
            institution.users = users;
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.getInstitution);

            return institution;
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.getInstitution);
            throw error;
        }
    }

    getInstitutionById = async (institutionId: string) => {
        this.loading = true;
        try {
            const institution = await agent.Institutions.getById(institutionId);
            return institution;
        } catch (error) {
            throw error;
        }
        finally {
            runInAction(() => this.loading = false);
        }
    }

    getInstitutionUsers = async (tenantId: string) => {
        store.loadingStore.startLoading(this.getInstitutionUsers);
        try {
            const users = await agent.Institutions.getUsers(tenantId);
            runInAction(() => {
                this.institutionUsers.clear();
                users.forEach((user) => this.institutionUsers.set(user.id, user));
                this.loading = false
            });
            store.loadingStore.stopLoading(this.getInstitutionUsers);
        } catch (error) {
            store.loadingStore.stopLoading(this.getInstitutionUsers);
            throw error;
        }
    }

    getUsersByInstitution = async (institutionId: string) => {
        store.loadingStore.startLoading(this.getUsersByInstitution);
        try {
            const users = await agent.Institutions.getUsersByInstitution(institutionId);
            runInAction(() => {
                this.usersByInstitution.set(institutionId, users);
                this.loading = false
            });
            store.loadingStore.stopLoading(this.getUsersByInstitution);
        } catch (error) {
            store.loadingStore.stopLoading(this.getUsersByInstitution);
            throw error;
        }
    }

    createInstitution = async (institution: InstitutionFormValues) => {
        store.loadingStore.startLoading(this.createInstitution);
        this.loading = true;
        try {
            await agent.Institutions.create(institution);
        } catch (error) {
            throw error;
        } finally {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.createInstitution);
        }
    }

    updateInstitution = async (institution: InstitutionFormValues) => {
        store.loadingStore.startLoading(this.updateInstitution);
        this.loading = true;
        try {
            await agent.Institutions.update(institution);
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.updateInstitution);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.updateInstitution);
            throw error;
        }
    }

    deleteInstitution = async (institutionId: string) => {
        store.loadingStore.startLoading(this.deleteInstitution);
        this.loading = true;
        try {
            await agent.Institutions.delete(institutionId);
            await store.profileStore.getUserTenants();
            if (this.selectedUserInstitution?.institutionId === institutionId) {
                const selectedTenantId = store.commonStore.selectedTenant;
                const selectedTenant = store.profileStore.userTenantList.find(t => t.id === selectedTenantId);
                if (selectedTenant) {
                    this.updateUserSelectedInstitution(selectedTenant);
                }
            }
            runInAction(() => {
                this.institutions.delete(institutionId);
                this.loading = false
            });
            store.loadingStore.stopLoading(this.deleteInstitution);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.deleteInstitution);
            throw error;
        }
    }

    getPermissions = async (institutionId: string) => {
        store.loadingStore.startLoading(this.getPermissions);
        this.loading = true;
        try {
            const institutionPermissions = await agent.Institutions.getUserPermissions(institutionId);
            runInAction(() => {
                this.selectedUserInstitution = institutionPermissions;

                this.currentInstitutionStudyType = institutionPermissions.study;
                this.loading = false;
            });
            store.loadingStore.stopLoading(this.getPermissions);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.getPermissions);
            throw error;
        }
    }

    getResponsiblePeople = async (institutionId: string) => {
        store.loadingStore.startLoading(this.getResponsiblePeople);
        this.loading = true;
        try {
            const responsiblePeopleResponse = await agent.Institutions.getResponsiblePeople(institutionId);
            const peopleWithAssignedInstitutions = responsiblePeopleResponse.map(person => ({
                ...person,
                institutionId: institutionId,
            }));

            runInAction(() => {
                this.responsiblePeople.set(institutionId, peopleWithAssignedInstitutions)
                this.loading = false;
            });
            store.loadingStore.stopLoading(this.getResponsiblePeople);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.getResponsiblePeople);
            throw error;
        }
    }

    reset() {
        this.institutions?.clear();
        this.institutionUsers?.clear();
        this.usersByInstitution?.clear();
        this.selectedUserInstitution = undefined;
        this.responsiblePeople?.clear();
        this.currentInstitutionStudyType = '';
    }
}