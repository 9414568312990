import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SearchBar from '../../../../components/form/SearchBar';
import StudyOverviewFilter from './StudyOverviewFilter';

type Props = {
    onSearch: (searchValue: string) => void;
    isLoading: boolean;
};

const StudyOverviewToolbar: React.FC<Props> = ({ onSearch, isLoading }) => {
    const { t } = useTranslation()
    return (
        <Grid container>
            <Grid item xs={9} sm={4} lg={3} marginBottom={3} >
                <SearchBar onSearch={onSearch} isRounded={false} placeholder={t("SEARCH_BY_CLIENT_OR_RESPONSIBLE_PEOPLE")} isLoading={isLoading} />
            </Grid>
            <Grid item xs={3} sm={4} lg={3}>
                <StudyOverviewFilter isLoading={isLoading} />
            </Grid>
        </ Grid>
    )
}

export default StudyOverviewToolbar