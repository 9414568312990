import { Controller } from "react-hook-form";
import { QuestionProps } from "./QuestionProps";
import { Box, debounce, InputAdornment, ListSubheader, MenuItem, Select, TextField } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { AnswerSubmit } from "../../../../data/models/survey";
import { useStore } from "../../../../stores/store";
import { useLocation, useParams } from "react-router-dom";
import { RoundButton } from "../../../../_styles/StyledButtons";
import { surveyLanguages } from "../../../../data/static/survey";
import { useTranslation } from "react-i18next";
import { SurveyText } from "../../../../_styles/survey/TextInput";

function SurveyDropdown(props: QuestionProps) {
    const {
        surveyStore: { getAnswer, setAnswer, submitAnswer, incrementCount, decrementCount },
    } = useStore();
    const question = getAnswer(props.id);
    const { t } = useTranslation();

    const { sessionId, surveyId, timelineId } = useParams();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    const language = params.get("lang");

    const onAnswerSelect = async (optionId: string | null, text?: string) => {
        if (props.preview) return;

        const answerSubmit: AnswerSubmit = {
            questionId: props.id,
            optionId: optionId,
            surveyId: surveyId!,
            timelineId: timelineId!,
            sessionId: sessionId!,
            text: text === "" ? null : text,
        };
        await submitAnswer(answerSubmit, token ?? undefined);
    };

    const isRtl = (): boolean => {
        return surveyLanguages.find((l) => l.code === language)
            ?.direction === "rtl"
    }

    const debounceFn = useCallback(debounce(onAnswerSelect, 400), []);

    let search = true;
    const [searchText, setSearchText] = useState("");

    const displayedOptions = useMemo(
        () =>
            props.options?.filter((option) =>
                option["title"]?.toLowerCase().includes(searchText.trim().toLowerCase())
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [searchText, props.options]
    );

    return (
        <Box width="100%" sx={{ position: "relative" }}>
            <Controller
                control={props.control}
                defaultValue={getAnswer(props.id) ? getAnswer(props.id).answer : null}
                name={`${props.id}.response`}
                render={({ field: { ref, onBlur, name, onChange, ...field }, fieldState: { error } }) => (
                    <>
                        <Select
                            onClose={() => setSearchText("")}
                            onChange={(e: any) => {
                                onChange(e);
                                let question = getAnswer(props.id);
                                const newAnswer = { ...question, answer: e.target.value, additional: null };
                                setAnswer(props.id, newAnswer);
                                onAnswerSelect(e.target.value);
                            }}
                            {...field}
                            label=""
                            size="small"
                            sx={{ fontSize: "14px", minWidth: "150px" }}
                            MenuProps={{ autoFocus: false }}
                        >
                            {search && (
                                <ListSubheader>
                                    <TextField
                                        size="small"
                                        autoFocus
                                        placeholder={t("TYPE_TO_SEARCH")}
                                        fullWidth
                                        sx={{
                                            marginTop: "10px",
                                            marginBottom: "10px",
                                        }}
                                        inputProps={{
                                            style: {
                                                fontSize: "13px",
                                                padding: "8px 10px",
                                            },
                                            'data-testid': `${name}-search`,
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={(e) => setSearchText(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key !== "Escape") {
                                                e.stopPropagation();
                                            }
                                        }}
                                    />
                                </ListSubheader>
                            )}
                            {displayedOptions?.map((o) => (
                                <MenuItem value={o.id} key={o.id} sx={{ fontSize: "14px" }}>
                                    {o.title}
                                </MenuItem>
                            ))}
                        </Select>
                        {props.options?.map(
                            (option) =>
                                question?.answer === option.id &&
                                option.conditionalText !== null && (
                                    <Controller
                                        key={option.title}
                                        name={`${props.id}.additional`}
                                        control={props.control}
                                        render={({ field: { onChange, value = "" } }) => (
                                            <Box>
                                                <SurveyText
                                                    placeholder={
                                                        option.conditionalText !== null
                                                            ? option.conditionalText
                                                            : "Answer here..."
                                                    }
                                                    variant="filled"
                                                    disabled={props.disabled}
                                                    multiline={true}
                                                    defaultValue={question.additional}
                                                    onChange={(e) => {
                                                        const text = e.target.value;

                                                        onChange(e);
                                                        let question = getAnswer(props.id);
                                                        const newAnswer = {
                                                            ...question,
                                                            additional: e.target.value,
                                                        };
                                                        setAnswer(props.id, newAnswer);
                                                        incrementCount();
                                                        setTimeout(() => decrementCount(), 400);
                                                        debounceFn(option.id, text);
                                                    }}
                                                    size="small"
                                                />
                                            </Box>
                                        )}
                                    />
                                )
                        )}
                        {question && question.answer && (
                            <RoundButton
                                variant="text"
                                sx={{
                                    width: "8.5rem",
                                    position: 'absolute',
                                    bottom: 0,
                                    left: isRtl() ? 0 : "auto",
                                    right: isRtl() ? "auto" : 0,
                                    padding: 0,
                                    "&:hover": {
                                        backgroundColor: "transparent",
                                    },
                                }}
                                onClick={() => {
                                    onChange(null);
                                    setAnswer(props.id, { answer: null, additional: null });
                                    onAnswerSelect(null);
                                }}
                            >
                                Clear section
                            </RoundButton>
                        )}
                    </>
                )}
            />
        </Box>
    );
}

export default SurveyDropdown;