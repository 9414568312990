import DoneIcon from "@mui/icons-material/Done";
import { Menu, MenuItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const FilterMenu = ({
    titleKey,
    items,
    selectedItems,
    handleSelect,
    isLoading,
    correctIndex,
    groupAnchorEl,
    setGroupAnchorEl,
}: {
    titleKey: string;
    items: any[];
    selectedItems: string[];
    handleSelect: (item: string) => void;
    isLoading: boolean;
    correctIndex: boolean;
    groupAnchorEl: HTMLElement | null;
    setGroupAnchorEl: (anchor: HTMLElement | null) => void;
}) => {
    const { t } = useTranslation();
    return (
        <Menu
            key={titleKey}
            open={Boolean(groupAnchorEl) && correctIndex}
            anchorEl={groupAnchorEl}
            onClose={() => setGroupAnchorEl(null)}
            hideBackdrop={true}
            transitionDuration={150}
            sx={{ height: "265px", width: "240px" }}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
        >
            {items.map((item) => (
                <MenuItem
                    disabled={isLoading}
                    key={item.value || item}
                    onClick={() => handleSelect(item.value || item)}
                    data-testid={`${item.value || item}`}
                    className="menu-item-option"
                    title={t(item.name || item)}
                >
                    <Typography sx={{ paddingRight: "10px", fontSize: "14px" }}>
                        {t(item.name || item)}
                    </Typography>
                    <DoneIcon
                        fontSize="small"
                        sx={{ color: selectedItems.includes(item.value || item) ? "#03b2ff" : "transparent" }}
                    />
                </MenuItem>
            ))}
        </Menu>
    );
};

