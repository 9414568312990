import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useStore } from "../../../../stores/store";

export const useStudyOverview = () => {
    const { studyOverviewStore, institutionStore, loadingStore } = useStore();
    const { getStudyOverview, studyOverviewList, selectedGroupsList, selectedTimelinesList, selectedStatusesList, selectedUpcomingEventStatusesList, selectedDueDateStatusesList } = studyOverviewStore;
    const { isLoading } = loadingStore;
    const { selectedUserInstitution, getInstitutionById } = institutionStore;

    const paginationString = sessionStorage.getItem("study-overview-pagination");
    const paginationParams = paginationString ? JSON.parse(paginationString) : null;

    const [pagination, setPagination] = useState({
        page: paginationParams?.page ?? 1,
        pageSize: paginationParams?.pageSize ?? 10,
    });
    const [searchQuery, setSearchQuery] = useState("");
    const [institutionStudyType, setInstititionStudyType] = useState<string>("");
    const maxPage = useRef(pagination.page);
    const isTableLoading: boolean = isLoading(getStudyOverview);

    const totalCount = useMemo(() => {
        return paginationParams?.TotalCount ?? studyOverviewList.length;
    }, [paginationParams?.TotalCount, studyOverviewList.length]);

    const fetchStudyOverviewData = useCallback(async (filterChange: boolean = false) => {
        if (!selectedUserInstitution?.institutionId) return;

        const params = {
            ...pagination,
            ...(searchQuery ? { searchTerm: searchQuery } : {}),
            ...(selectedGroupsList.length > 0 ? { groupFilter: selectedGroupsList } : {}),
            ...(selectedTimelinesList.length > 0 ? { timelineFilter: selectedTimelinesList } : {}),
            ...(selectedStatusesList.length > 0 ? { statusFilter: selectedStatusesList } : {}),
            ...(selectedUpcomingEventStatusesList.length > 0 ? { upcomingEventFilter: selectedUpcomingEventStatusesList } : {}),
            ...(selectedDueDateStatusesList.length > 0 ? { upcomingEventStatusFilter: selectedDueDateStatusesList } : {}),
        };
        await getStudyOverview(
            selectedUserInstitution.institutionId,
            params,
            filterChange
        );
    }, [getStudyOverview, pagination, searchQuery, selectedUserInstitution?.institutionId, selectedGroupsList, selectedTimelinesList, selectedStatusesList, selectedUpcomingEventStatusesList, selectedDueDateStatusesList]);

    const fetchStudyOverviewDataRef = useRef(fetchStudyOverviewData);

    useEffect(() => {
        const fetchInstitutionType = async () => {
            if (selectedUserInstitution) {
                const institution = await getInstitutionById(selectedUserInstitution.institutionId);
                setInstititionStudyType(institution?.cantonTeam);
            }
        }
        fetchInstitutionType();
    }, [getInstitutionById, selectedUserInstitution]);

    useEffect(() => {
        fetchStudyOverviewDataRef.current = fetchStudyOverviewData;
    }, [fetchStudyOverviewData]);

    useEffect(() => {
        setPagination((prev) => ({
            ...prev,
            page: 1,
        }));
        fetchStudyOverviewDataRef.current(true);
    }, [selectedUserInstitution, searchQuery, selectedGroupsList, selectedTimelinesList, selectedStatusesList, selectedUpcomingEventStatusesList, selectedDueDateStatusesList]);

    useEffect(() => {
        fetchStudyOverviewDataRef.current();
    }, [pagination.page, pagination.pageSize, selectedUserInstitution]);

    useEffect(() => {
        return () => {
            studyOverviewStore.reset();
        };
    }, [studyOverviewStore]);

    const handlePageChange = (newPage: number, newPageSize: number) => {
        const updatedPagination = { page: newPage + 1, pageSize: newPageSize };
        setPagination(updatedPagination);
        maxPage.current = newPage + 1;

    };
    const handleSearchChange = (search: string) => {
        setSearchQuery(search);
        setPagination({ page: 1, pageSize: pagination.pageSize });
    };

    return {
        studyOverviewList,
        pagination,
        handlePageChange,
        handleSearchChange,
        isTableLoading,
        rowCount: totalCount,
        institutionStudyType,
        fetchStudyOverviewData
    };
};
