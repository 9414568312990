import { Email } from "@mui/icons-material";
import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Switch, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import FormInputSelect from "../../../components/form/FormInputSelect";
import { FormInputText } from "../../../components/form/FormInputText";
import clientStore from "../../../stores/clientStore";
import { useStore } from "../../../stores/store";
import { RoundButton } from "../../../_styles/StyledButtons";

const buttonStyles = {
    width: 'auto'
};

export interface ResendEmailConfirmation {
    isCustom: boolean;
    type: "email" | "whatsapp" | null;
    email?: string | null;
    whatsAppNumber?: string | null;
}


function ResendNotificationDialog() {
    const { t } = useTranslation();
    const {
        dialogStore: { dialog },
    } = useStore();
    const {
        title,
        message,
        open,
        param,
        onCancel,
        onConfirm,
        button,
    } = dialog;
    const [loading, setLoading] = useState(false);
    const [contactMethod, setContactMethod] = useState("unset");
    const [useCustomContact, setUseCustomContact] = useState(false);
    const {
        control,
        reset,
        watch,
    } = useForm({
        defaultValues: {
            email: '',
            whatsapp: ''
        }
    });

    const email = watch("email");
    const whatsapp = watch("whatsapp");

    const handleConfirm = async () => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const phoneRegex = /^\+[1-9]{1}[0-9]{3,14}$/

        if (contactMethod === "email" && (!email || !emailRegex.test(email))) {
            toast.error(t("INVALID_EMAIL"));
            return;
        }

        if (contactMethod === "whatsapp" && (!whatsapp || !phoneRegex.test(whatsapp))) {
            toast.error(t("INVALID_PHONE_NUMBER"));
            return;
        }

        setLoading(true);

        const requestBody = {
            isCustom: useCustomContact,
            type: useCustomContact ? contactMethod.toUpperCase() : null,
            email: useCustomContact && contactMethod === "email" ? email : null,
            whatsAppNumber: useCustomContact && contactMethod === "whatsapp" ? whatsapp : null,
        };
        try {
            await onConfirm(requestBody);
        } catch (error) {
            onCancel();
        } finally {
            reset();
            setUseCustomContact(false);
            setContactMethod("unset");
            setLoading(false);
        }
    };

    const handleClose = () => {
        reset();
        setUseCustomContact(false);
        setContactMethod("unset");
        onCancel();
    };

    const handleSwitch = () => {
        if (useCustomContact) {
            setContactMethod("unset");
        } else {
            setContactMethod("email");
        }
        setUseCustomContact(!useCustomContact);
    };

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            PaperProps={{
                style: { padding: '1rem', minWidth: '450px' }

            }}
        >
            <DialogTitle>{title}

            </DialogTitle>
            <DialogContentText>{message}
                <FormControlLabel
                    style={{ marginLeft: '10px' }}
                    control={<Switch checked={useCustomContact} onChange={() => handleSwitch()} />}
                    label={t("TOGGLE_TO_SWITCH_CONTACTS")}
                />
            </DialogContentText>

            <DialogContent>
                {useCustomContact && (
                    <>
                        <Box mb={2}>
                            <FormInputSelect
                                name="contactType"
                                control={control}
                                options={
                                    [
                                        { label: t("GENERAL_EMAIL"), value: "email" },
                                        { label: t("WHATSAPP_NUMBER"), value: "whatsapp" },
                                    ]}
                                valueKey="value"
                                labelKey="label"
                                onSelect={(e) => setContactMethod(e.target.value)}
                                defaultValue={contactMethod}
                            />
                        </Box>
                        <Box>
                            {contactMethod === "email" ?
                                <FormInputText key="email" name="email" control={control} label={t("GENERAL_EMAIL")} />
                                :
                                <FormInputText key="whatsapp" name="whatsapp" control={control} label={t("WHATSAPP_NUMBER")} />
                            }
                        </Box>
                    </>)
                }
            </DialogContent >
            <DialogActions>
                <RoundButton variant="text" color="inherit" sx={buttonStyles} onClick={handleClose} title="Cancel" data-testid="dialog-cancel">
                    <Typography fontSize="13px">{t("GENERAL_CANCEL")}</Typography>
                </RoundButton>
                <RoundButton variant="contained" color={button.color} sx={buttonStyles} onClick={handleConfirm} disabled={loading} title="Confirm" data-testid="dialog-confirm">
                    {loading ? (
                        <CircularProgress color="secondary" size={25} />
                    ) : (
                        <Typography fontSize="13px">{button.text}</Typography>
                    )}
                </RoundButton>
            </DialogActions>
        </Dialog >
    );
}

export default observer(ResendNotificationDialog);