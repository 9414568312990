import { makeAutoObservable, runInAction } from "mobx";
import { StudyOverviewDTO } from "../data/models/studyOverview";
import agent from "../utils/agent";
import { store } from "./store";

export default class StudyOverviewStore {
    private studyOverview: Map<string, StudyOverviewDTO> = new Map<string, StudyOverviewDTO>();
    private selectedGroups: string[] = [];
    private selectedTimelines: string[] = [];
    private selectedStatuses: string[] = [];

    private selectedUpcomingEventStatuses: string[] = [];
    private selectedDueDateStatuses: string[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    get studyOverviewList(): StudyOverviewDTO[] {
        return Array.from(this.studyOverview!.values());
    }

    get selectedGroupsList(): string[] {
        return this.selectedGroups;
    }

    get selectedTimelinesList(): string[] {
        return this.selectedTimelines;
    }

    get selectedStatusesList(): string[] {
        return this.selectedStatuses;
    }

    get selectedDueDateStatusesList(): string[] {
        return this.selectedDueDateStatuses;
    }

    get selectedUpcomingEventStatusesList(): string[] {
        return this.selectedUpcomingEventStatuses;
    }

    set selectedGroupsList(group: string[]) {
        this.selectedGroups = group;
    }

    set selectedDueDateStatusesList(statuses: string[]) {
        this.selectedDueDateStatuses = statuses;
    }

    set selectedUpcomingEventStatusesList(statuses: string[]) {
        this.selectedUpcomingEventStatuses = statuses;
    }

    set selectedTimelinesList(timelines: string[]) {
        this.selectedTimelines = timelines;
    }

    set selectedStatusesList(statuses: string[]) {
        this.selectedStatuses = statuses;
    }

    toggleGroups = (groups: string[]) => {
        this.selectedGroupsList = groups;
    }

    toggleTimelines = (timelines: string[]) => {
        this.selectedTimelinesList = timelines;
    }

    toggleStatuses = (statuses: string[]) => {
        this.selectedStatusesList = statuses;
    }

    toggleDueDateStatuses = (statuses: string[]) => {
        this.selectedDueDateStatuses = statuses;
    }

    toggleUpcomingEventStatuses = (statuses: string[]) => {
        this.selectedUpcomingEventStatusesList = statuses;
    }

    getStudyOverview = async (
        institutionId: string,
        queryParams: {
            page: number;
            pageSize: number;
            searchTerm?: string;
            groupFilter?: string[];
            timelineFilter?: string[];
            statusFilter?: string[];
            upcomingEventFilter?: string[];
            upcomingEventStatusFilter?: string[];
        }, filterChange?: boolean
    ) => {
        store.loadingStore.startLoading(this.getStudyOverview);

        try {
            const response = await agent.StudyOverview.getStudyOverview(institutionId, queryParams);
            store.commonStore.setStudyOverviewPagination(JSON.stringify(response.pagination));
            runInAction(() => {
                if (filterChange) { this.clearStudyOverview() }
                response.data.forEach(studyOverview => {
                    this.studyOverview?.set(studyOverview.clientId, studyOverview);
                });
            });
        } catch (error) {
            console.error("Error fetching study overview:", error);
        } finally {
            store.loadingStore.stopLoading(this.getStudyOverview);
        }
    }

    getClientSessionHistory = async (clientId: string) => {
        store.loadingStore.startLoading(this.getClientSessionHistory);

        try {
            const response = await agent.StudyOverview.getClientSessionHistoryActions(clientId);

            return response;
        } catch (error) {
            console.error("Error fetching study overview:", error);
        } finally {
            store.loadingStore.stopLoading(this.getClientSessionHistory);
        }
    }

    clearFilters = () => {
        runInAction(() => {
            this.selectedGroupsList = [];
            this.selectedTimelinesList = [];
            this.selectedStatusesList = [];
            this.selectedDueDateStatusesList = [];
            this.selectedUpcomingEventStatusesList = [];
        });
    }

    clearStudyOverview() {
        this.studyOverview?.clear();
    }

    reset() {
        this.clearStudyOverview();
        this.clearFilters();
    }
}