import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import BlockIcon from '@mui/icons-material/Block';
import ReplayIcon from '@mui/icons-material/Replay';
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { toast } from "react-toastify";

import { format } from "date-fns";

import { useStore } from "../../../stores/store";
import DropdownMenu from "../../../components/DropdownMenu";
import GenericTable from "../../../components/GenericTable";
import { UserDeactivationRequest, UserDto, UserStatus } from "../../../data/models/user";
import UserRegisterForm from "./UserRegisterForm";
import { TableText } from "../../../_styles/table/TableText";
import { TableHeader } from "../../../_styles/table/TableHeader";
import { arrayToString, isUUID } from "../../../utils/utils";
import { CheckIcon } from "../../../_styles/icons/CheckIcon";
import { TableSearch } from "../../../_styles/table/TableSearch";
import UserFilters from "./UserFilters";
import "../styles.css";
import { useTranslation } from "react-i18next";
import UserStatusChip from "../../../components/UserStatusChip";
import UsersListActionDialog from "../../Client/Form/UsersListActionDialog";

function UserTable() {
    const { t } = useTranslation();
    const { userStore, tenantStore, sidebarStore, dialogStore, institutionStore } = useStore();
    const { userList, getUsers, getUser, deleteUser, reset2fa, deactivateUser, reactivateUser, anonymizeUser } = userStore;
    const { userSettingsTenant } = tenantStore;
    const { openSidebar } = sidebarStore;
    const { openDialog, closeDialog } = dialogStore;
    const { selectedUserInstitution } = institutionStore
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [institutionFilters, setInstitutionFilters] = useState<string[]>([]);

    useEffect(() => {
        if (userSettingsTenant) {
            const fetchData = async () => {
                setLoading(true);
                await getUsers(userSettingsTenant.id);
                setLoading(false);
            };

            fetchData();
        }
    }, [userSettingsTenant, getUsers]);

    const onEditUser = async (id: string) => {
        const user = await getUser(id, userSettingsTenant?.id!, selectedUserInstitution?.institutionId!);

        openSidebar(<UserRegisterForm user={user} />, t("EDIT_USER"));
    };

    const onDeleteUser = (id: string) => {
        openDialog(
            t("DIALOG_TITLE_DELETE"),
            t("DIALOG_MSG_USER"),
            async (userDeactivationRequest: UserDeactivationRequest) => {
                console.log(userDeactivationRequest);
                await deleteUser(id, selectedUserInstitution?.institutionId as string, userDeactivationRequest);
                closeDialog();
                toast.success(t("TOAST_USER_DELETE"));
            },
            closeDialog,
            {
                text: t("GENERAL_DELETE"),
                color: "error",
            },
            false,
        );
    };

    const onReset2fa = (id: string) => {
        openDialog(
            t("DIALOG_TITLE_RESET_2FA"),
            t("DIALOG_MSG_RESET_2FA"),
            async (userDeactivationRequest: UserDeactivationRequest) => {
                await reset2fa(id, selectedUserInstitution?.institutionId as string, userDeactivationRequest);
                closeDialog();
                toast.success(t("TOAST_USER_2FA_RESET"));
            },
            closeDialog,
            {
                text: t("RESET_2FA"),
                color: "warning",
            },
            false
        );
    };

    const onDeactivateUser = (id: string) => {
        openDialog(
            t("DIALOG_TITLE_DEACTIVATE_USER"),
            t("DIALOG_MSG_DEACTIVATE_USER"),
            async (userDeactivationRequest: UserDeactivationRequest) => {
                await deactivateUser(id, selectedUserInstitution?.institutionId as string, userDeactivationRequest);
                closeDialog();
                toast.success(t("TOAST_USER_DEACTIVATED"));
                await getUsers(userSettingsTenant!.id);
            },
            closeDialog,
            {
                text: t("DEACTIVATE"),
                color: "error",
            },
            false
        );
    };

    const onReactivateUser = (id: string) => {
        openDialog(
            t("DIALOG_TITLE_REACTIVATE_USER"),
            t("DIALOG_MSG_REACTIVATE_USER"),
            async (userDeactivationRequest: UserDeactivationRequest) => {
                await reactivateUser(id, selectedUserInstitution?.institutionId as string, userDeactivationRequest);
                closeDialog();
                toast.success(t("TOAST_USER_REACTIVATED"));
                await getUsers(userSettingsTenant!.id);
            },
            closeDialog,
            {
                text: t("REACTIVATE"),
                color: "warning",
            },
            false
        );
    };


    const onAnonymizeUser = (id: string) => {
        openDialog(
            t("DIALOG_TITLE_ANONYMIZE_USER"),
            t("DIALOG_MSG_ANONYMIZE_USER"),
            async (userDeactivationRequest: UserDeactivationRequest) => {
                await anonymizeUser(id, selectedUserInstitution?.institutionId as string, userDeactivationRequest);
                closeDialog();
                toast.success(t("TOAST_USER_ANONYMIZED"));
                await getUsers(userSettingsTenant!.id);
            },
            closeDialog,
            {
                text: t("ANONYMIZE"),
                color: "error",
            },
            false
        )
    };

    const options = [
        {
            id: "edit",
            name: t("GENERAL_EDIT"),
            icon: (
                <ModeEditOutlinedIcon
                    sx={{ position: "absolute", right: 0, paddingRight: 1 }}
                    color="primary"
                />
            ),
            onClick: (id: string) => onEditUser(id),
        },
        {
            id: "reset-2fa",
            name: t("RESET_2FA"),
            icon: (
                <LockOpenOutlinedIcon
                    sx={{ position: "absolute", right: 0, paddingRight: 1 }}
                    color="primary"
                />
            ),
            onClick: (id: string) => onReset2fa(id),
        },
        {
            id: "deactivate",
            name: t("DEACTIVATE"),
            icon: (
                <BlockIcon
                    sx={{ position: "absolute", right: 0, paddingRight: 1 }}
                    color="primary"
                />
            ),
            onClick: (id: string) => onDeactivateUser(id),
        },
        {
            id: "reactivate",
            name: t("REACTIVATE"),
            icon: (
                <ReplayIcon
                    sx={{ position: "absolute", right: 0, paddingRight: 1 }}
                    color="primary"
                />
            ),
            onClick: (id: string) => onReactivateUser(id),
        },
        {
            id: "anonymize",
            name: t("ANONYMIZE"),
            icon: (
                <SecurityOutlinedIcon
                    sx={{ position: "absolute", right: 0, paddingRight: 1 }}
                    color="primary"
                />
            ),
            onClick: (id: string) => onAnonymizeUser(id),
        },
        {
            id: "delete",
            name: t("GENERAL_DELETE"),
            onClick: (id: string) => onDeleteUser(id),
            icon: (
                <DeleteForeverOutlinedIcon
                    sx={{ position: "absolute", right: 0, paddingRight: 1 }}
                    color="error"
                />
            ),
            customStyle: {
                backgroundColor: "#ffdfdf",
                color: "#FF0000",
                ":hover": { backgroundColor: "#FFABAB" },
            },
        },
    ];

    const getFilteredData = () => {
        if (institutionFilters.length === 0) return userList;

        const institutionFilter = userList.filter(user => {
            return user.institutions.some(institution => institutionFilters.includes(institution))
        }
        );

        return institutionFilter;
    }

    const getData = () => {
        const filteredData = getFilteredData();
        if (searchQuery !== "") {
            return filteredData.filter((user) => {
                const fullName = user.firstName + " " + user.lastName;
                return (
                    fullName.toLowerCase().includes(searchQuery.toLowerCase().trim()) ||
                    user.email.toLowerCase().includes(searchQuery.toLowerCase().trim())
                );
            });
        }
        return filteredData;
    };

    return (
        <>
            <UsersListActionDialog />
            <GenericTable<UserDto>
                columns={[
                    {
                        header: <TableHeader>{t("USER_FULL_NAME")}</TableHeader>,
                        key: "firstName",
                        render: (user) => (
                            <TableText>
                                {user.firstName} {user.lastName}
                            </TableText>
                        ),
                        sort: (a, b) => {
                            const aFullName = `${a.firstName} ${a.lastName}`;
                            const bFullName = `${b.firstName} ${b.lastName}`;
                            return aFullName
                                .toLowerCase()
                                .localeCompare(bFullName.toLowerCase());
                        },
                    },
                    {
                        header: <TableHeader>{t("USER_EMAIL")}</TableHeader>,
                        key: "email",
                        render: (user) => (
                            <TableText sx={{ fontStyle: isUUID(user.email) ? "italic" : "" }}>
                                {isUUID(user.email) ? "" : user.email}
                            </TableText>
                        ),
                        sort: (a, b) => {
                            const isAUUID = isUUID(a.email);
                            const isBUUID = isUUID(b.email);

                            if (isAUUID && isBUUID) {
                                return 0;
                            } else if (isAUUID) {
                                return -1;
                            } else if (isBUUID) {
                                return 1;
                            } else {
                                return a.email.toLowerCase().localeCompare(b.email.toLowerCase());
                            }
                        },
                    },
                    {
                        header: <TableHeader>{t("USER_JOIN_DATE")}</TableHeader>,
                        key: "createdAt",
                        render: (user) => (
                            <TableText>
                                {format(new Date(user.createdAt), "dd MMMM, yyyy")}
                            </TableText>
                        ),
                        sort: (a, b) => {
                            const aDate = new Date(a.createdAt);
                            const bDate = new Date(b.createdAt);
                            return aDate > bDate ? 1 : aDate < bDate ? -1 : 0;
                        },
                    },
                    {
                        header: <TableHeader>{t("INSTITUTIONS")}</TableHeader>,
                        key: "institutions",
                        render: (user) => (
                            <TableText
                                sx={{
                                    fontStyle: user.institutions.length === 0 ? "italic" : "",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                {user.institutions.length === 0 && "No institutions"}
                                {arrayToString(user.institutions)}
                            </TableText>
                        ),
                    },
                    {
                        header: <TableHeader>{t("STATUS")}</TableHeader>,
                        key: "status",
                        render: (user) => (
                            <TableText>
                                <UserStatusChip status={user.status} />
                            </TableText>
                        ),
                    },
                    {
                        header: (
                            <TableHeader sx={{ display: "flex", justifyContent: "center" }}>
                                Tenant admin
                            </TableHeader>
                        ),
                        key: "isTenantAdmin",
                        render: (user) => (
                            <TableText
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginRight: "40px",
                                }}
                            >
                                {user.isTenantAdmin && <CheckIcon />}
                            </TableText>
                        ),
                        sort: (a, b) => {
                            if (a.isTenantAdmin === b.isTenantAdmin) {
                                return 0;
                            } else if (a.isTenantAdmin) {
                                return -1;
                            } else {
                                return 1;
                            }
                        },
                        width: 10,
                    },
                    {
                        header: <Typography></Typography>,
                        key: "actions",
                        render: (user) => (
                            <Box mr={2}>
                                {isUUID(user.email) ?
                                    <Tooltip
                                        title={t("USER_GO_TO_USERS_TO_EDIT")}
                                        placement="bottom"
                                    >
                                        <div>
                                            <IconButton disabled>
                                                <MoreVertIcon />
                                            </IconButton>
                                        </div>
                                    </Tooltip>
                                    :
                                    <DropdownMenu
                                        options={options
                                            .filter(option => !(
                                                ([UserStatus.ACTIVE, UserStatus.ANON].includes(user.status) && option.id === 'reactivate') ||
                                                ([UserStatus.DEACTIVATED, UserStatus.ANON].includes(user.status) && option.id === 'deactivate') ||
                                                (user.status === UserStatus.ANON && ['anonymize', 'edit', 'reset-2fa'].includes(option.id))
                                            ))
                                            .map(option => ({
                                                ...option,
                                                onClick: () => option.onClick(user.id),
                                            }))}
                                    />
                                }
                            </Box>
                        ),
                        className: "settings-table-actions",
                    },
                ]}
                rows={getData()}
                props={{
                    containerProps: { className: "settings-table" },
                    tableContainerProps: { className: "survey-table-container-data" },
                    tableHeadProps: { className: "survey-table-header" },
                    rowProps: { className: "survey-row" },
                }}
                usePagination
                rowKey="email"
                loading={loading}
                toolbar={
                    <Box display={"flex"} alignItems={"center"}>
                        <TableSearch
                            placeholder={t("USER_SEARCH")}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            sx={{
                                width: "fit-content"
                            }}
                            InputProps={{
                                endAdornment: <SearchIcon sx={{ color: "#ddd" }} />,
                            }}
                        />
                        <UserFilters institutionFilters={institutionFilters} setInstitutionFilters={setInstitutionFilters} />
                    </Box>
                }
            />
        </>
    );
}

export default observer(UserTable);
