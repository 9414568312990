import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { Box, Button, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { sessionStatusValues } from "../../../../data/models/session";
import { studyOverviewEventStatusValues } from "../../../../data/models/studyOverview";
import { assesementTimePoints, studyOverviewGroups } from "../../../../data/static/booking";
import { useStore } from "../../../../stores/store";
import { toggleSelection } from "../../../../utils/helpers/clientHelper";
import { buttonStyle } from "../../../Booking/Options/CalendarFilter";
import { FilterMenu } from "./FilterMenu";

const StudyOverviewFilter: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
    const { t } = useTranslation();
    const { studyOverviewStore } = useStore();
    const {
        toggleGroups, toggleTimelines, toggleStatuses, toggleUpcomingEventStatuses, toggleDueDateStatuses,
        selectedGroupsList, selectedTimelinesList, selectedStatusesList, selectedUpcomingEventStatusesList, selectedDueDateStatusesList,
        clearFilters
    } = studyOverviewStore;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [groupAnchorEl, setGroupAnchorEl] = useState<null | HTMLElement>(null);
    const [index, setIndex] = useState(0);
    const [isClosing, setIsClosing] = useState(false);

    useEffect(() => {
        return () => { clearFilters() };
    }, [clearFilters]);

    const handleMenuClose = () => {
        setIsClosing(true);
        setAnchorEl(null);
        setGroupAnchorEl(null);
        setIndex(-1);
        setTimeout(() => setIsClosing(false), 500);
    }

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

    const handleMenu = (event: any, newIndex: number) => {
        if (isClosing) return;
        if (newIndex === -1) {
            setGroupAnchorEl(null);
        } else {
            setGroupAnchorEl(event.currentTarget);
        }
        setIndex(newIndex);
    };

    const handleGroupSelect = (group: string) => toggleSelection(selectedGroupsList, group, toggleGroups);
    const handleTimelineSelect = (timeline: string) => toggleSelection(selectedTimelinesList, timeline, toggleTimelines);
    const handleStatusSelect = (status: string) => toggleSelection(selectedStatusesList, status, toggleStatuses);
    const handleUpcomingEventSelect = (status: string) => toggleSelection(selectedUpcomingEventStatusesList, status, toggleUpcomingEventStatuses);
    const handleDueDateSelect = (status: string) => toggleSelection(selectedDueDateStatusesList, status, toggleDueDateStatuses);

    const isActive = (): boolean => {
        return selectedGroupsList.length > 0 || selectedTimelinesList.length > 0 || selectedStatusesList.length > 0 || selectedUpcomingEventStatusesList.length > 0 || selectedDueDateStatusesList.length > 0;
    };

    const onClearFilters = async () => {
        handleMenuClose();
        clearFilters();
    };

    return (
        <Box sx={{ marginLeft: 1 }}>
            <Button onClick={handleMenuClick}
                className={`${isActive() ? "selected-item" : ""}`}
                sx={{ ...buttonStyle, color: isActive() ? "#03b3ff" : "#616161" }}
                data-testid="coordination-overview-filter-button"
                disabled={isLoading}
            >
                <FilterListOutlinedIcon fontSize="small" sx={{ marginRight: "5px" }} />
                {t("GENERAL_FILTER")}
            </Button>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                sx={{ height: "265px", width: "240px" }}
            >
                <MenuItem
                    className={`menu-item-option ${index === 1 ? "menu-selected-option" : ""}`}
                    data-testid="group-filter-button"
                    onMouseOver={(e) => handleMenu(e, 1)}
                >
                    <ListItemText
                        primary={<Typography variant="body1" sx={{ fontSize: "14px" }}>{t("GENERAL_GROUP")}</Typography>}
                        sx={{ mr: 1 }}
                    />
                    <ArrowForwardIosIcon sx={{ fontSize: "14px", marginTop: "2px" }} />
                </MenuItem>

                <MenuItem
                    className={`menu-item-option ${index === 2 ? "menu-selected-option" : ""}`}
                    data-testid="timelines-filter-button"
                    onMouseOver={(e) => handleMenu(e, 2)}
                >
                    <ListItemText
                        primary={<Typography variant="body1" sx={{ fontSize: "14px" }}>{t("SESSION_TIMELINE")}</Typography>}
                        sx={{ mr: 1 }}
                    />
                    <ArrowForwardIosIcon sx={{ fontSize: "14px", marginTop: "2px" }} />
                </MenuItem>

                <MenuItem
                    className={`menu-item-option ${index === 3 ? "menu-selected-option" : ""}`}
                    data-testid="statuses-filter-button"
                    onMouseOver={(e) => handleMenu(e, 3)}
                >
                    <ListItemText
                        primary={<Typography variant="body1" sx={{ fontSize: "14px" }}>{t("STATUS")}</Typography>}
                        sx={{ mr: 1 }}
                    />
                    <ArrowForwardIosIcon sx={{ fontSize: "14px", marginTop: "2px" }} />
                </MenuItem>

                <MenuItem
                    className={`menu-item-option ${index === 4 ? "menu-selected-option" : ""}`}
                    data-testid="upcoming-statuses-filter-button"
                    onMouseOver={(e) => handleMenu(e, 4)}
                >
                    <ListItemText
                        primary={<Typography variant="body1" sx={{ fontSize: "14px" }}>{t("CLIENT_EVENTS")}</Typography>}
                        sx={{ mr: 1 }}
                    />
                    <ArrowForwardIosIcon sx={{ fontSize: "14px", marginTop: "2px" }} />
                </MenuItem>

                <MenuItem
                    className={`menu-item-option ${index === 5 ? "menu-selected-option" : ""}`}
                    data-testid="duedate-filter-button"
                    onMouseOver={(e) => handleMenu(e, 5)}
                >
                    <ListItemText
                        primary={<Typography variant="body1" sx={{ fontSize: "14px" }}>{t("TODO_DATE")}</Typography>}
                        sx={{ mr: 1 }}
                    />
                    <ArrowForwardIosIcon sx={{ fontSize: "14px", marginTop: "2px" }} />
                </MenuItem>

                {(isActive()) && (
                    <MenuItem
                        className="menu-item-option"
                        data-testid="clear-filters-button"
                        onMouseOver={(e) => handleMenu(e, -1)}
                        sx={{ fontSize: "14px" }}
                        onClick={() => {
                            onClearFilters();
                            handleMenuClose();
                        }}
                    >
                        {t("GENERAL_CLEAR")}
                    </MenuItem>
                )}
            </Menu>
            <FilterMenu
                titleKey="GENERAL_GROUP"
                items={studyOverviewGroups}
                selectedItems={selectedGroupsList}
                handleSelect={handleGroupSelect}
                isLoading={isLoading}
                correctIndex={index === 1}
                groupAnchorEl={groupAnchorEl}
                setGroupAnchorEl={setGroupAnchorEl}
            />
            <FilterMenu
                titleKey="SESSION_TIMELINE"
                items={Object.values(assesementTimePoints)}
                selectedItems={selectedTimelinesList}
                handleSelect={handleTimelineSelect}
                isLoading={isLoading}
                correctIndex={index === 2}
                groupAnchorEl={groupAnchorEl}
                setGroupAnchorEl={setGroupAnchorEl}
            />
            <FilterMenu
                titleKey="STATUS"
                items={Object.values(sessionStatusValues)}
                selectedItems={selectedStatusesList}
                handleSelect={handleStatusSelect}
                isLoading={isLoading}
                correctIndex={index === 3}
                groupAnchorEl={groupAnchorEl}
                setGroupAnchorEl={setGroupAnchorEl}

            />
            <FilterMenu
                titleKey="CLIENT_EVENTS"
                items={Object.values(assesementTimePoints)}
                selectedItems={selectedUpcomingEventStatusesList}
                handleSelect={handleUpcomingEventSelect}
                isLoading={isLoading}
                correctIndex={index === 4}
                groupAnchorEl={groupAnchorEl}
                setGroupAnchorEl={setGroupAnchorEl}
            />
            <FilterMenu
                titleKey="TODO_DATE"
                items={Object.values(studyOverviewEventStatusValues)}
                selectedItems={selectedDueDateStatusesList}
                handleSelect={handleDueDateSelect}
                isLoading={isLoading}
                correctIndex={index === 5}
                groupAnchorEl={groupAnchorEl}
                setGroupAnchorEl={setGroupAnchorEl}
            />
        </Box>
    );
};

export default observer(StudyOverviewFilter);